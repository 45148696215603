import { BannerPageDTO, BannerPageNameDTO } from 'app/modules/settings/infrastructure/dto/BannerPageDTO';
import { BannerPage } from 'app/modules/settings/domain/entity/BannerPage';
import { BannerPageName, BannerPageNameValue } from 'app/modules/settings/domain/valueObject/BannerPageName';
import { BannerPageOrigin } from 'app/modules/settings/domain/valueObject/BannerPageOrigin';
import { BannerPageId } from 'app/modules/settings/domain/valueObject/BannerPageId';
import { TranslationArrayMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/TranslationArrayMapper';

export class BannerPageMapper {

    private static _fromPageName(pageNameDto: BannerPageNameDTO): BannerPageName {
        switch (pageNameDto) {
            case BannerPageNameDTO.CART:
                return new BannerPageName(BannerPageNameValue.CART);
            case BannerPageNameDTO.OUTLET:
                return new BannerPageName(BannerPageNameValue.OUTLET);
            default:
                throw new Error('Invalid BannerPageName');
        }
    }

    public static toDomain(bannerPageDto: BannerPageDTO):BannerPage {
        return new BannerPage(
            new BannerPageId(bannerPageDto.id),
            new BannerPageOrigin(bannerPageDto.origin),
            this._fromPageName(bannerPageDto.page_id),
            TranslationArrayMapper.toDomain(bannerPageDto.translations),
        );

    }

}
