import { BannerPageDTO } from 'app/modules/settings/infrastructure/dto/BannerPageDTO';
import { BannerPageArray } from 'app/modules/settings/domain/valueObject/BannerPageArray';
import { BannerPageMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/BannerPageMapper';

export class BannerPageArrayMapper {

    public static toDomain(bannerPagesDto: BannerPageDTO[]): BannerPageArray {
        const bannerPageArray: BannerPageArray = new BannerPageArray();

        bannerPagesDto.forEach((item) => {
            const bannerPageDomain = BannerPageMapper.toDomain(item);
            bannerPageArray.addBannerPage(bannerPageDomain);
        });

        return bannerPageArray;
    }

}
