import { Id } from 'app/shared/domain/value-objects/id';
import { SettingDTO, SettingNameDTO } from 'app/modules/settings/infrastructure/dto/SettingDTO';
import { Setting } from 'app/modules/settings/domain/entity/Setting';
import { SettingOrigin } from 'app/modules/settings/domain/valueObject/SettingOrigin';
import { SettingName, SettingNameValue } from 'app/modules/settings/domain/valueObject/SettingName';
import { SettingValue } from 'app/modules/settings/domain/valueObject/SettingValue';

export class SettingMapper {

    private static _settingsNameToDomain(name: SettingNameDTO): SettingName {
        switch (name) {
            case SettingNameDTO.BIG_RESERVE_UNIT:
                return new SettingName(SettingNameValue.BIG_RESERVE_UNIT);
            case SettingNameDTO.BIG_RESERVE_M2:
                return new SettingName(SettingNameValue.BIG_RESERVE_M2);
            case SettingNameDTO.STOCK_RESERVATION_TIME:
                return new SettingName(SettingNameValue.STOCK_RESERVE_TIME);
            case SettingNameDTO.STOCK_RESERVATION_TIME_CHECKOUT:
                return new SettingName(SettingNameValue.STOCK_RESERVE_TIME_CHECKOUT);
            case SettingNameDTO.CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR:
                return new SettingName(SettingNameValue.CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR);
            case SettingNameDTO.CARRIER_PICKUP_AFTER_CUT_OFF_HOUR:
                return new SettingName(SettingNameValue.CARRIER_PICKUP_AFTER_CUT_OFF_HOUR);
            case SettingNameDTO.CARRIER_INSURANCE:
                return new SettingName(SettingNameValue.CARRIER_INSURANCE);
            case SettingNameDTO.CUT_OFF_HOUR:
                return new SettingName(SettingNameValue.CUT_OFF_HOUR);
            case SettingNameDTO.PICK_HANDLE_PRICE:
                return new SettingName(SettingNameValue.PICK_HANDLE_PRICE);
            case SettingNameDTO.PICK_HANDLE_ACCOUNT:
                return new SettingName(SettingNameValue.PICK_HANDLE_ACCOUNT);
            default:
                throw new Error('Invalid SettingName');
        }
    }

    public static toDomain(settingDto: SettingDTO):Setting {
        return new Setting(
            new Id(settingDto.id),
            new SettingOrigin(settingDto.origin),
            this._settingsNameToDomain(settingDto.name),
            new SettingValue(settingDto.value),
        );

    }

}
