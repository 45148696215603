import { AxiosResponse } from 'axios';
import { SettingDTO } from 'app/modules/settings/infrastructure/dto/SettingDTO';
import { AxiosInstance } from 'app/shared/infrastructure/api/web-service/axios-instance';
import { getToken } from 'app/shared/helpers/config';
import { PutSettingItemRequest } from 'app/modules/settings/infrastructure/dto/request/PutSettingItemRequest';
import { PatchShipmentAppointmentRequest } from 'app/modules/settings/infrastructure/dto/request/PatchShipmentAppointmentRequest';
import { PatchPickHandleRequest } from 'app/modules/settings/infrastructure/dto/request/PatchPickHandleRequest';
import { BannerPageDTO } from 'app/modules/settings/infrastructure/dto/BannerPageDTO';
import { PutBannerItemRequest } from 'app/modules/settings/infrastructure/dto/request/PutBannerItemRequest';

export interface TencerAdminApiClient {
    getSettings: () => Promise<AxiosResponse<SettingDTO[]>>
    putSettingItem: (request: PutSettingItemRequest) => Promise<AxiosResponse<string>>
    patchShipmentAppointment: (request: PatchShipmentAppointmentRequest) => Promise<AxiosResponse<void>>
}

export class AxiosTencerAdminApiClient implements TencerAdminApiClient {

    public constructor() {
    }

    public async getSettings(): Promise<AxiosResponse<SettingDTO[]>> {
        const endpoint = '/api/admin/setting/';
        return AxiosInstance.get<undefined,
        AxiosResponse <SettingDTO[]>>(endpoint, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async putSettingItem(request: PutSettingItemRequest): Promise<AxiosResponse<string>> {
        const endpoint = '/api/admin/settings/';
        return AxiosInstance.put<undefined,
        AxiosResponse <string>>(endpoint, request, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async patchShipmentAppointment(request: PatchShipmentAppointmentRequest): Promise<AxiosResponse<void>> {
        const endpoint = '/api/admin/settings/shipment-appointment';
        return AxiosInstance.patch(endpoint, request, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async patchPickHandle(request: PatchPickHandleRequest): Promise<AxiosResponse<void>> {
        const endpoint = '/api/admin/settings/pick-handle';
        return AxiosInstance.patch(endpoint, request, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async fetchGetBannersPages(): Promise<AxiosResponse<BannerPageDTO[]>> {
        const endpoint = '/api/admin/banners/';
        return AxiosInstance.get<undefined,
        AxiosResponse <BannerPageDTO[]>>(endpoint, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async fetchPutBannerItem(request: PutBannerItemRequest): Promise<AxiosResponse<string>> {
        const endpoint = `/api/admin/banners/${request.bannerId}`;
        return AxiosInstance.put<undefined,
        AxiosResponse <string>>(endpoint, { translations: request.translations }, {
            params: {
                translations: request.translations,
            },
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

}
