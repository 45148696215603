import { Translation } from 'app/modules/settings/domain/entity/Translation';
import { TranslationDTO } from 'app/modules/settings/infrastructure/dto/TranslationDTO';
import { TranslationId } from 'app/modules/settings/domain/valueObject/TranslationId';
import { TranslationLanguage } from 'app/modules/settings/domain/valueObject/TranslationLanguage';
import { TranslationText } from 'app/modules/settings/domain/valueObject/TranslationText';

export class TranslationMapper {

    public static toDomain(translationDto: TranslationDTO):Translation {
        return new Translation(
            new TranslationId(translationDto.id),
            new TranslationLanguage(translationDto.language),
            new TranslationText(translationDto.text),
            translationDto.link ? new URL(translationDto.link) : null,
        );

    }

    public static fromDomain(translation: Translation):TranslationDTO {
        return {
            id: translation.id.value,
            language: translation.language.value,
            text: translation.text.value,
            link: translation.link ? translation.link.toString() : null,
        };

    }

}
