import { TranslationArray } from 'app/modules/settings/domain/entity/TranslationArray';
import { TranslationDTO } from 'app/modules/settings/infrastructure/dto/TranslationDTO';
import { TranslationMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/TranslationMapper';

export class TranslationArrayMapper {

    public static toDomain(translationsDTO: TranslationDTO[]): TranslationArray {
        const translationArray: TranslationArray = new TranslationArray();

        translationsDTO.forEach((item) => {
            const translationDomain = TranslationMapper.toDomain(item);
            translationArray.addTranslation(translationDomain);
        });

        return translationArray;
    }

    public static fromDomain(translations: TranslationArray): TranslationDTO[] {
        const translationsDTO: TranslationDTO[] = [];

        translations.translations.forEach((item) => {
            translationsDTO.push(TranslationMapper.fromDomain(item));
        });

        return translationsDTO;
    }

}
