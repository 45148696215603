import { SettingDTO } from 'app/modules/settings/infrastructure/dto/SettingDTO';
import { SettingsArray } from 'app/modules/settings/domain/entity/SettingsArray';
import { SettingMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/SettingMapper';

export class SettingsArrayMapper {

    public static toDomain(SettingsDto: SettingDTO[]): SettingsArray {
        const settingArray: SettingsArray = new SettingsArray();

        SettingsDto.map((setting) => {
            const settingDomain = SettingMapper.toDomain(setting);
            settingArray.addSetting(settingDomain);
            return true;
        });

        return settingArray;
    }

}
