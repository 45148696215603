import { TranslationDTO } from 'app/modules/settings/infrastructure/dto/TranslationDTO';

export interface BannerPageDTO{
    id:string,
    origin:number,
    page_id:BannerPageNameDTO,
    translations: TranslationDTO[],
}

export enum BannerPageNameDTO {
    CART = 'cart',
    OUTLET = 'outlet'
}
